import React from 'react';
import {Col, FormCheck, FormLabel} from 'react-bootstrap';
import {Field, useFormikContext} from 'formik';
import SelectField from '../../elements/ReactSelect/SelectField';
import {useGetCalculatorConfigQuery} from '../../../features/apiSlice';
import IconField from "../../elements/Formik/IconField.jsx";

const MtplOfferWidgetAdditionalInformation = () => {
    const {isSubmitting} = useFormikContext();

    const {
        data: calculatorConfig,
        isSuccess,
    } = useGetCalculatorConfigQuery();

    return (<>
        <Col>
            <FormLabel htmlFor="personalNumber">ЕГН/ЕИК на застраховащ</FormLabel>
            <IconField type="text" name="policyHolderPersonalNumber" id="policyHolderPersonalNumber"
                         pattern="\d{9,13}" disabled={isSubmitting} maxLength={13}
                         inputMode="decimal" prependIcon='user'/>
        </Col>
        <Col>
            <FormLabel htmlFor="vehiclePurpose">Предназначение</FormLabel>
            <Field component={SelectField} name="vehiclePurpose"
                   options={isSuccess ? Object.entries(calculatorConfig.vehiclePurposes).map(
                       ([code, description]) => ({
                           label: description,
                           value: code,
                       })
                   ) : []}
                   required disabled={isSubmitting} isClearable={true}
                   placeholder="Автоматичен избор" />
        </Col>
        <Col>
            <FormLabel htmlFor="additionalCover">Допълнително покритие</FormLabel>
            <Field component={SelectField} name="additionalCover"
                   options={isSuccess ? Object.entries(calculatorConfig.additionalCovers).map(
                       ([code, description]) => ({
                           label: description,
                           value: code,
                       })
                   ) : []}
                   required disabled={isSubmitting} isClearable={true}
                   placeholder="без покритие" />
        </Col>
        <Col>
            <FormCheck as={Field} name="rightSteering" id="rightSteering"
                        label="МПС е с десен волан" disabled={isSubmitting}>
            </FormCheck>
            <FormCheck as={Field} name="faultForAccident" id="faultForAccident"
                       label="Вина при ПТП в последните 3 години" disabled={isSubmitting}>
            </FormCheck>
        </Col>
    </>);
};

export default MtplOfferWidgetAdditionalInformation;
