import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'react-bootstrap';
import {kwToHp, vehicleTypes} from '../../../utils.js';
import TextWithCopyButton from '../TextWithCopyButton.jsx';
import {useUpdateVehicleFromVinDecoderMutation, useUpdateVehicleMutation} from "../../../features/apiSlice.js";
import SmallSpinner from "../../elements/Spinner/SmallSpinner.jsx";
import Alert from "../../elements/ReactBootstrap/Alert.jsx";
import ErrorWidget from "../ErrorWidget.jsx";
import DateShort from "../../elements/DateTime/DateShort.jsx";
import EditInputGroup from "../../elements/ReactBootstrap/EditInputGroup.jsx";
import EditButton from "../SimpleButtons/EditButton.jsx";
import VehiclePrintButton from "../../elements/PrintButtons/VehiclePrintButton.jsx";
import VehicleTypeWidget from "./VehicleTypeWidget.jsx";

const VehicleInfoWidget = ({
    vehicle,
    printing,
    setPrinting,
}) => {
    const [newValue, setNewValue] = useState('');
    const [editPlateNumber, setEditPlateNumber] = useState(false);
    const [editFirstRegistrationDate, setEditFirstRegistrationDate] = useState(false);
    const [editRegistrationCertificateNumber, setEditRegistrationCertificateNumber] = useState(false);
    const [editMaxWeightF3, setEditMaxWeightF3] = useState(false);
    const [editVehicleType, setEditVehicleType] = useState(false);
    const [editBrandName, setEditBrandName] = useState(false);
    const [editModelName, setEditModelName] = useState(false);
    const [editVehicleClass, setEditVehicleClass] = useState(false);
    const [editRegistrationType, setEditRegistrationType] = useState(false);
    const [editEngineType, setEditEngineType] = useState(false);
    const [editMaxWeightF1, setEditMaxWeightF1] = useState(false);
    const [editColorName, setEditColorName] = useState(false);
    const [editVehicleWeightG, setEditVehicleWeightG] = useState(false);
    const [editEngineCapacity, setEditEngineCapacity] = useState(false);
    const [editMaxPowerKw, setEditMaxPowerKw] = useState(false);
    const [editSeatsCount, setEditSeatsCount] = useState(false);

    const isEditOn = editPlateNumber || editFirstRegistrationDate || editRegistrationCertificateNumber ||
        editMaxWeightF3 || editVehicleType || editBrandName || editModelName || editVehicleClass ||
        editRegistrationType || editEngineType || editMaxWeightF1 || editColorName || editVehicleWeightG ||
        editEngineCapacity || editMaxPowerKw || editSeatsCount;

    const [updateFromVinDecoder, {
        isSuccess: updateSuccess,
        error: updateError,
        isError: updateIsError,
        isLoading: updateLoading,
    }] = useUpdateVehicleFromVinDecoderMutation(vehicle);

    const [update, {
        isUpdateSuccess: isSuccess,
        updateError: error,
        isUpdateError: isError,
        isUpdateLoading: isLoading,
    }] = useUpdateVehicleMutation(vehicle)
    return (<>
        <Row as="dl" className="mb-0">
            {isEditOn && <Col>
                <Alert variant="warning" heading="Важно">
                    Декларирам, че нося наказателна отговорност за подадени неверни данни!
                </Alert>
            </Col>}
        </Row>
        <Row as="dl" className="mb-0">
            <Col sm={2} as="dt">Тип МПС (D)</Col>
            <Col sm={4} as="dd">
                {vehicle.type ? vehicleTypes[vehicle.type] :
                    <>{!editVehicleType ? <EditButton onClick={() => {
                            setEditVehicleType(oldState => !oldState);
                        }}/> :
                        <VehicleTypeWidget
                            onSubmit={values => {
                                update([vehicle.id, {type: values.type}]);
                                setEditVehicleType(oldState => !oldState);
                        }} />
                    }</>
                }
            </Col>
        </Row>
        <Row as="dl" className="mb-0">
            <Col sm={2} as="dt">ДКН (A)</Col>
            <Col sm={4} as="dd">
            {vehicle.plateNumber && <TextWithCopyButton text={vehicle.plateNumber} />}
                <>
                    {!editPlateNumber ? <EditButton onClick={() => {
                    setEditPlateNumber(oldState => !oldState);
                    setNewValue(vehicle.plateNumber);
                    }}/> :
                    <EditInputGroup formControlOnChange={e=> setNewValue(e.target.value)}
                        saveOnClick={() => {
                            update([vehicle.id, {
                                plateNumber: newValue
                            }]);
                            setEditPlateNumber(oldState => !oldState);
                            setNewValue('');
                        }}
                        fieldValue={newValue}
                        closeOnClick={() => setEditPlateNumber(oldState => !oldState)}
                    /> }
                </>
            </Col>
            <Col sm={2} as="dt">Дата на първа регистрация (B)</Col>
            <Col sm={4} as="dd">
                {vehicle.firstRegistrationDate ? <><DateShort date={vehicle.firstRegistrationDate} /> г.</> :
                    <>
                        {!editFirstRegistrationDate ? <EditButton onClick={() => {
                            setEditFirstRegistrationDate(oldState => !oldState);
                            setNewValue(vehicle.firstRegistrationDate);
                        }}/> :
                        <EditInputGroup formControlOnChange={e=> setNewValue(e.target.value)}
                            saveOnClick={() => {
                                update([vehicle.id, {
                                    firstRegistrationDate: newValue
                                }]);
                                setEditFirstRegistrationDate(oldState => !oldState);
                                setNewValue('');
                            }}
                            fieldValue={newValue}
                            closeOnClick={() => setEditFirstRegistrationDate(oldState => !oldState)}
                        />}
                    </>
                }
            </Col>
        </Row>
        <Row as="dl" className="mb-0">
            <Col sm={2} as="dt">Номер на СРМПС</Col>
            <Col sm={4} as="dd">
                {vehicle.registrationCertificateNumber && <TextWithCopyButton text={vehicle.registrationCertificateNumber} />}
                <>
                    {!editRegistrationCertificateNumber ? <EditButton onClick={() => {
                        setEditRegistrationCertificateNumber(oldState => !oldState);
                        setNewValue(vehicle.registrationCertificateNumber);
                    }}/> :
                    <EditInputGroup formControlOnChange={e=> setNewValue(e.target.value)}
                        saveOnClick={() => {
                            update([vehicle.id, {
                                registrationCertificateNumber: newValue
                            }]);
                            setEditRegistrationCertificateNumber(oldState => !oldState);
                            setNewValue('');
                        }}
                        fieldValue={newValue}
                        closeOnClick={() => setEditRegistrationCertificateNumber(oldState => !oldState)}
                    />}
                </>
            </Col>
            <Col sm={2} as="dt">Максимално натоварване (F3)</Col>
            <Col sm={4} as="dd">
                {!!vehicle.maxWeightF3 ? <>{vehicle.maxWeightF3.toString(10)} кг.</> :
                    <>
                        {!editMaxWeightF3 ? <EditButton onClick={() => {
                            setEditMaxWeightF3(oldState => !oldState);
                            setNewValue(vehicle.maxWeightF3);
                        }}/> :
                        <EditInputGroup type="number" formControlOnChange={e=> setNewValue(e.target.value)}
                            saveOnClick={() => {
                                update([vehicle.id, {
                                    maxWeightF3: parseInt(newValue)
                                }]);
                                setEditMaxWeightF3(oldState => !oldState);
                                setNewValue('');
                            }}
                            fieldValue={newValue}
                            closeOnClick={() => setEditMaxWeightF3(oldState => !oldState)}
                        />}
                    </>
                }
            </Col>
        </Row>
        <Row as="dl" className="mb-0">
            <Col sm={2} as="dt">VIN/шаси (E)</Col>
            <Col sm={4} as="dd">
                {vehicle.vin ? <TextWithCopyButton text={vehicle.vin} /> : ''}
            </Col>
            <Col sm={2} as="dt">Тип МПС от ЗК</Col>
            <Col sm={4} as="dd">{vehicle.vehicleType}</Col>
        </Row>
        <Row as="dl" className="mb-0">
            <Col sm={2} as="dt">Категория МПС (J)</Col>
            <Col sm={4} as="dd">
                {vehicle.vehicleClass ? vehicle.vehicleClass :
                    <>
                        {!editVehicleClass ? <EditButton onClick={() => {
                            setEditVehicleClass(oldState => !oldState);
                            setNewValue(vehicle.vehicleClass);
                        }}/> :
                        <EditInputGroup formControlOnChange={e=> setNewValue(e.target.value)}
                            saveOnClick={() => {
                                update([vehicle.id, {
                                    vehicleClass: newValue
                                }]);
                                setEditVehicleClass(oldState => !oldState);
                                setNewValue('');
                            }}
                            fieldValue={newValue}
                            closeOnClick={() => setEditVehicleClass(oldState => !oldState)}
                        />}
                    </>
                }
            </Col>
            <Col sm={2} as="dt">Вид регистрация</Col>
            <Col sm={4} as="dd">
                {vehicle.registrationType ? vehicle.registrationType :
                    <>
                        {!editRegistrationType ? <EditButton onClick={() => {
                            setEditRegistrationType(oldState => !oldState);
                            setNewValue(vehicle.registrationType);
                        }}/> :
                        <EditInputGroup formControlOnChange={e=> setNewValue(e.target.value)}
                            saveOnClick={() => {
                                update([vehicle.id, {
                                    registrationType: newValue
                                }]);
                                setEditRegistrationType(oldState => !oldState);
                                setNewValue('');
                            }}
                            fieldValue={newValue}
                            closeOnClick={() => setEditRegistrationType(oldState => !oldState)}
                        />}
                    </>
                }
            </Col>
        </Row>
        <Row as="dl" className="mb-0">
            <Col sm={2} as="dt">Марка и модел (D.1)</Col>
            <Col sm={2} as="dd">{vehicle.brandName}
                {!editBrandName ? <EditButton onClick={() => {
                        setEditBrandName(oldState => !oldState);
                        setNewValue(vehicle.brandName);
                    }}/> :
                    <EditInputGroup formControlOnChange={e=> setNewValue(e.target.value)}
                        saveOnClick={() => {
                            update([vehicle.id, {
                                brandName: newValue
                            }]);
                            setEditBrandName(oldState => !oldState);
                            setNewValue('');
                        }}
                        fieldValue={newValue}
                        closeOnClick={() => setEditBrandName(oldState => !oldState)}
                    />}
            </Col>
            <Col sm={2} as="dd">{vehicle.modelName}
                {!editModelName ? <EditButton onClick={() => {
                        setEditModelName(oldState => !oldState);
                        setNewValue(vehicle.modelName);
                    }}/> :
                    <EditInputGroup formControlOnChange={e=> setNewValue(e.target.value)}
                        saveOnClick={() => {
                            update([vehicle.id, {
                                modelName: newValue
                            }]);
                            setEditModelName(oldState => !oldState);
                            setNewValue('');
                        }}
                        fieldValue={newValue}
                        closeOnClick={() => setEditModelName(oldState => !oldState)}
                    />}
            </Col>
            <Col sm={2} as="dt">Тип двигател (P.3)</Col>
            <Col sm={4} as="dd">
                {vehicle.engineType ? vehicle.engineType :
                    <>
                        {!editEngineType ? <EditButton onClick={() => {
                            setEditEngineType(oldState => !oldState);
                            setNewValue(vehicle.engineType);
                        }}/> :
                        <EditInputGroup formControlOnChange={e=> setNewValue(e.target.value)}
                            saveOnClick={() => {
                                update([vehicle.id, {
                                    engineType: newValue
                                }]);
                                setEditEngineType(oldState => !oldState);
                                setNewValue('');
                            }}
                            fieldValue={newValue}
                            closeOnClick={() => setEditEngineType(oldState => !oldState)}
                        />}
                    </>
                }
            </Col>
        </Row>
        <Row as="dl" className="mb-0">
            <Col sm={2} as="dt">Максимално натоварване (F1)</Col>
            <Col sm={4} as="dd">
                {!!vehicle.maxWeightF1 ? <>{vehicle.maxWeightF1.toString(10)} кг.</> :
                    <>
                        {!editMaxWeightF1 ? <EditButton onClick={() => {
                            setEditMaxWeightF1(oldState => !oldState);
                            setNewValue(vehicle.maxWeightF1);
                        }}/> :
                        <EditInputGroup type="number" formControlOnChange={e=> setNewValue(e.target.value)}
                            saveOnClick={() => {
                                update([vehicle.id, {
                                    maxWeightF1: parseInt(newValue)
                                }]);
                                setEditMaxWeightF1(oldState => !oldState);
                                setNewValue('');
                            }}
                            fieldValue={newValue}
                            closeOnClick={() => setEditMaxWeightF1(oldState => !oldState)}
                        />}
                    </>
                }
            </Col>
            <Col sm={2} as="dt">Цвят (R)</Col>
            <Col sm={4} as="dd">
                {(vehicle.colorName || vehicle.colorCode) ? <>
                    {vehicle.colorName}{' '}{vehicle.colorCode}
                </> :
                    <>
                        {!editColorName ? <EditButton onClick={() => {
                            setEditColorName(oldState => !oldState);
                            setNewValue(vehicle.colorName);
                        }}/> :
                        <EditInputGroup formControlOnChange={e=> setNewValue(e.target.value)}
                            saveOnClick={() => {
                                update([vehicle.id, {
                                    colorName: newValue
                                }]);
                                setEditColorName(oldState => !oldState);
                                setNewValue('');
                            }}
                            fieldValue={newValue}
                            closeOnClick={() => setEditColorName(oldState => !oldState)}
                        />}
                    </>
                }
            </Col>
        </Row>
        <Row as="dl" className="mb-0">
            <Col sm={2} as="dt">Маса на МПС (G)</Col>
            <Col sm={4} as="dd">
                {!!vehicle.vehicleWeightG ? <>{vehicle.vehicleWeightG.toString(10)} кг.</> :
                    <>
                        {!editVehicleWeightG ? <EditButton onClick={() => {
                            setEditVehicleWeightG(oldState => !oldState);
                            setNewValue(vehicle.vehicleWeightG);
                        }}/> :
                        <EditInputGroup type="number" formControlOnChange={e=> setNewValue(e.target.value)}
                            saveOnClick={() => {
                                update([vehicle.id, {
                                    vehicleWeightG: parseInt(newValue)
                                }]);
                                setEditVehicleWeightG(oldState => !oldState);
                                setNewValue('');
                            }}
                            fieldValue={newValue}
                            closeOnClick={() => setEditVehicleWeightG(oldState => !oldState)}
                        />}
                    </>
                }
            </Col>
            <Col sm={2} as="dt">Обем на двигателя (P.1)</Col>
            <Col sm={4} as="dd">
                {!!vehicle.engineCapacity ? <>{vehicle.engineCapacity.toString(10)} см<sup>3</sup></> :
                    <>
                        {!editEngineCapacity ? <EditButton onClick={() => {
                            setEditEngineCapacity(oldState => !oldState);
                            setNewValue(vehicle.engineCapacity);
                        }}/> :
                        <EditInputGroup type="number" formControlOnChange={e=> setNewValue(e.target.value)}
                            saveOnClick={() => {
                                update([vehicle.id, {
                                    engineCapacity: parseInt(newValue)
                                }]);
                                setEditEngineCapacity(oldState => !oldState);
                                setNewValue('');
                            }}
                            fieldValue={newValue}
                            closeOnClick={() => setEditEngineCapacity(oldState => !oldState)}
                        />}
                    </>
                }
            </Col>
        </Row>
        <Row as="dl" className="mb-0">
            <Col sm={2} as="dt">Мощност на двигателя (P.2)</Col>
            <Col sm={4} as="dd">
                {!!vehicle.maxPowerKw ? <>{vehicle.maxPowerKw.toString(10)} kW ={' '}
                    {Math.round(kwToHp(vehicle.maxPowerKw)).toString(10)} к.с.
                </> :
                    <>
                        {!editMaxPowerKw ? <EditButton onClick={() => {
                            setEditMaxPowerKw(oldState => !oldState);
                            setNewValue(vehicle.maxPowerKw);
                        }}/> :
                        <EditInputGroup type="number" formControlOnChange={e=> setNewValue(e.target.value)}
                            saveOnClick={() => {
                                update([vehicle.id, {
                                    maxPowerKw: parseFloat(newValue)
                                }]);
                                setEditMaxPowerKw(oldState => !oldState);
                                setNewValue('');
                            }}
                            fieldValue={newValue}
                            closeOnClick={() => setEditMaxPowerKw(oldState => !oldState)}
                        />}
                    </>
                }
            </Col>
            <Col sm={2} as="dt">Брой места (S.1)</Col>
            <Col sm={4} as="dd">
                {(!!vehicle.seatsCount || vehicle.seatsCountText) ?
                    vehicle.seatsCountText || vehicle.seatsCount.toString(10) :
                    <>
                        {!editSeatsCount ? <EditButton onClick={() => {
                            setEditSeatsCount(oldState => !oldState);
                            setNewValue(vehicle.seatsCount);
                        }}/> :
                        <EditInputGroup type="number" formControlOnChange={e=> setNewValue(e.target.value)}
                            saveOnClick={() => {
                                update([vehicle.id, {
                                    seatsCount: parseInt(newValue)
                                }]);
                                setEditSeatsCount(oldState => !oldState);
                                setNewValue('');
                            }}
                            fieldValue={newValue}
                            closeOnClick={() => setEditSeatsCount(oldState => !oldState)}
                        />}
                    </>
                }
            </Col>
        </Row>
        <Row as="dl" className="mb-0">
            <Col sm={6} xl={3}><Button variant="outline-secondary" className="w-100" onClick={() => updateFromVinDecoder([vehicle.id])}>
                <i className="fas fa-retweet" /> Обновяване от VIN Decoder
            </Button></Col>
            <Col sm={6} xl={3}><VehiclePrintButton vehicle={vehicle} printing={false} disabled={printing}
                                                  setDisabled={setPrinting} /></Col>
        </Row>
        <Row as="dl" className="mb-0">
            <Col>
                {(updateLoading || isLoading) && <SmallSpinner />}
                {updateSuccess && <Alert variant="success" heading="Успешно обновяване от VIN Decoder" />}
                {isSuccess && <Alert variant="success" heading="Успешно обновяване" />}
                {(updateIsError || isError) && <ErrorWidget error={(updateError || error)} />}
            </Col>
        </Row>




        {/*</Row>*/}
    </>);
};

VehicleInfoWidget.propTypes = {
    vehicle: PropTypes.shape({
        vin: PropTypes.string.isRequired,
        plateNumber: PropTypes.string,
        firstRegistrationDate: PropTypes.string,
        vehicleClass: PropTypes.string,
        vehicleType: PropTypes.string,
        registrationType: PropTypes.string,
        colorName: PropTypes.string,
        colorCode: PropTypes.string,
        brandName: PropTypes.string,
        modelName: PropTypes.string,
        maxWeightF1: PropTypes.number,
        maxWeightF3: PropTypes.number,
        vehicleWeightG: PropTypes.number,
        engineCapacity: PropTypes.number,
        maxPowerKw: PropTypes.number,
        seatsCount: PropTypes.number,
        seatsCountText: PropTypes.string,
        registrationCertificateNumber: PropTypes.string,
    }).isRequired,
};

export default VehicleInfoWidget;
