import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import PremiumAmountSum from '../../elements/Values/PremiumAmountSum';
import {useGetVersionQuery} from '../../../features/apiSlice';

const PremiumButton = ({
    offer,
    disabled,

    ...props
}) => {
    const {
        data: version,
        isSuccess,
    } = useGetVersionQuery();

    const supportedInsuranceCompanyNumbers = useMemo(() => [
        2, // bulins
        3, // bulstrad
        5, //uniqa
        6, // dzi
        7, // euroins
        8, // generali
        22, // levins
        26, // groupama
        30, // dallbogg
    ], []);

    const children = <><PremiumAmountSum arr={offer.installments} field="totalAmount" /> (
                                <PremiumAmountSum arr={offer.installments} field="totalAmountCurrency" />)</>;

    if (disabled) {
        return (
            <Button disabled={true} {...props}>{children}</Button>
        );
    }

    const isInsuranceCompanySupported = supportedInsuranceCompanyNumbers.indexOf(offer.insuranceCompany.number) > -1;
    const isDev = isSuccess && !version.insuranceCompanyCommunicationIsProd;
    const isDisabledOnProd = !isDev && !isInsuranceCompanySupported;

    const disabledMessage = 'Към момента, не може да се издаде полица през софтуера. ' +
        'Моля ползвайте портала на застрахователната компания.';

    return isDisabledOnProd ? (
        <Button {...props} className="disabled" style={{pointerEvents: 'unset'}} title={disabledMessage}
                onClick={() => alert(disabledMessage)}>{children}</Button>
    ) : (
        <Button as={NavLink} to={'/mtpl/new/offer/' + offer.id} {...props}>{children}</Button>
    );
};

PremiumButton.defaultProps = {
    disabled: false,
    variant: 'outline-success',
};

PremiumButton.propTypes = {
    disabled: PropTypes.bool,
    offer: PropTypes.shape({
        id: PropTypes.string.isRequired,
        installments: PropTypes.arrayOf(PropTypes.shape({
            totalAmount: PropTypes.number.isRequired,
        })).isRequired,
    }).isRequired,
    variant: PropTypes.string,
};

export default PremiumButton;
