import React from 'react';
import {Field, Form, Formik} from 'formik';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken';
import PropTypes from 'prop-types';
import {Button, Col, Container, FormControl, FormLabel, InputGroup, Row} from 'react-bootstrap';
import useFormPersistedValues from '../../../hooks/useFormPersistedValues';
import AutoSubmitToken from '../../elements/Formik/AutoSubmitToken';

const MtplOfferWidget = ({
    autoFocus,
    autoSubmit,
    onSubmit,
    children,
}) => {
    const formName = 'mtplOfferForm';

    const formInitialValues = useFormPersistedValues(formName);

    return (
        <Formik
            initialValues={formInitialValues || {
                plateNumber: '',
                registrationCertificateNumber: '',
                personalNumber: '',
                policyHolderPersonalNumber: '',
            }}
            onSubmit={onSubmit}
        >
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0">
                    <Row className="row-cols-1 row-cols-sm-2 row-cols-xl-4 g-3">
                        <Col>
                            <FormLabel htmlFor="plateNumber">Номер на автомобил (ДКН)</FormLabel>
                            <InputGroup>
                                <InputGroup.Text>
                                    <i className="fas fa-car-alt"/>
                                </InputGroup.Text>
                                <FormControl as={Field} type="text" name="plateNumber" id="plateNumber" required
                                    pattern="[ABEKMHOPCTYXabekmhopctyxАВЕКМНОРСТУХавекмнорстух\d]{5,8}"
                                    disabled={isSubmitting} maxLength={8} autoFocus={autoFocus && !autoSubmit}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <FormLabel htmlFor="registrationCertificateNumber">Номер на талон (СРМПС)</FormLabel>
                            <InputGroup>
                                <InputGroup.Text>
                                    <i className="fas fa-file"/>
                                </InputGroup.Text>
                                <FormControl as={Field} type="text" name="registrationCertificateNumber"
                                             id="registrationCertificateNumber" required pattern="\d{9}"
                                             disabled={isSubmitting} maxLength={9} inputMode="decimal"/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <FormLabel htmlFor="personalNumber">ЕГН/ЕИК на собственик</FormLabel>
                            <InputGroup>
                                <InputGroup.Text>
                                    <i className="fas fa-user"/>
                                </InputGroup.Text>
                                <FormControl as={Field} type="text" name="personalNumber" id="personalNumber"
                                              required pattern="\d{9,13}" disabled={isSubmitting} maxLength={13}
                                              inputMode="decimal"/>
                            </InputGroup>
                        </Col>
                        <Col className="align-self-end">
                            <Button variant="success" type="submit" className="w-100"
                                    disabled={isSubmitting}>
                                <i className="fas fa-search" /> Намери най-добрата оферта
                            </Button>
                        </Col>
                        {children}
                    </Row>
                    {autoSubmit && <AutoSubmitToken />}
                    <AutoPersistToken formName={formName}/>
                </Container>
            )}
        </Formik>
    );
};

MtplOfferWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    autoSubmit: PropTypes.bool,
};

MtplOfferWidget.defaultProps = {
    autoFocus: false,
    autoSubmit: false,
};

export default MtplOfferWidget;
