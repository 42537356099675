import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {sum} from '../utils';
import {clearTokens, getRefreshToken, getToken, setTokens} from '../authStorage.js';
import {E_ALREADY_LOCKED, Mutex, tryAcquire} from 'async-mutex';

const refreshMutex = new Mutex();

const anonymousBaseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    prepareHeaders: (headers) => {
        headers.set('accept', 'application/json');

        return headers;
    },
});

const authenticatedBaseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    prepareHeaders: (headers) => {
        headers.set('accept', 'application/json');

        const token = getToken();

        if (token) {
            headers.set('authorization', 'Bearer ' + token);
        }

        return headers;
    },
});

const authenticatedBaseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await authenticatedBaseQuery(args, api, extraOptions);

    if (!result.error || result.error.status !== 401) {
        return result;
    }

    try {
        await tryAcquire(refreshMutex).runExclusive(async () => {
            const refreshToken = getRefreshToken();

            if (refreshToken === null) {
                clearTokens();
                throw new Error('No refresh token found');
            }

            const refreshResult = await anonymousBaseQuery({
                url: 'login/refresh-token',
                method: 'POST',
                body: {
                    refresh_token: refreshToken,
                },
            }, api, extraOptions);

            if (!refreshResult.data) {
                clearTokens();
                throw new Error('Refreshing token failed');
            }

            setTokens(refreshResult.data);
        });
    }
    catch (e) {
        if (e === E_ALREADY_LOCKED) {
            await refreshMutex.waitForUnlock();
        }
    }

    return authenticatedBaseQuery(args, api, extraOptions);
};

const query = builder => url => builder.query({
    query: () => url,
});

const toArray = value => value instanceof Array ? value : [value];

const getWithAppendedParameters = url => params => [url, ...toArray(params)].filter(fragment =>
    fragment !== undefined && fragment !== null
).join('/');

const postWithBody = url => params => ({
    url: url,
    method: 'POST',
    body: params,
});

const mutationPostWithBody = builder => url => builder.mutation({
    query: postWithBody(url),
});

export const anonymousApiSlice = createApi({
    reducerPath: 'anonymousApi',
    baseQuery: anonymousBaseQuery,
    endpoints: builder => ({
        getCalculatorConfig: query(builder)('calculator/config'),
        getVersion: query(builder)('info/version'),
        login: mutationPostWithBody(builder)('login'),
    }),
});

export const authenticatedApiSlice = createApi({
    reducerPath: 'authenticatedApi',
    baseQuery: authenticatedBaseQueryWithReauth,
    tagTypes: [
        'Address',
        'Application',
        'BsoProtocol',
        'Client',
        'GreenCard',
        'Installment',
        'InsuranceBroker',
        'InsuranceBrokerCommissionSetting',
        'InsuranceCompany',
        'InsuranceCompanyMtplAdditionalAmount',
        'Offer',
        'Office',
        'Policy',
        'Product',
        'Sticker',
        'UabCard',
        'UabCardVoucher',
        'AssistanceCard',
        'User',
        'UserLevel',
        'UserLevelCommissionSetting',
        'UserLimit',
        'UserLimitAudit',
        'UserVoucherDetail',
        'ValuationCertificate',
        'Vehicle',
        'Voucher',
        'VoucherDetail',
        'FscCategory',
    ],
    endpoints: builder => ({
        getApplication: builder.query({
            query: getWithAppendedParameters('application'),
            providesTags: [
                'Application',
                'Product',
            ],
        }),
        getBsoProtocol: builder.query({
            query: getWithAppendedParameters('bso-protocol'),
            providesTags: [
                'BsoProtocol',
            ],
        }),
        getClient: builder.query({
            query: getWithAppendedParameters('client'),
            providesTags: [
                'Client',
                'InsuranceCompany',
                'Policy',
                'User',
                'Vehicle',
            ],
        }),
        getCurrentInsuranceBroker: builder.query({
            query: () => 'insurance-broker',
            providesTags: [
                'InsuranceBroker',
                'Office',
                'User',
            ],
        }),
        getCurrentOffice: builder.query({
            query: () => 'office',
            providesTags: [
                'InsuranceBroker',
                'Office',
                'User',
            ],
        }),
        getCurrentUser: builder.query({
            query: () => 'user',
            providesTags: [
                'InsuranceBroker',
                'Office',
                'User',
            ],
        }),
        currentUserHasRole: builder.query({
            query: getWithAppendedParameters('user/role'),
            providesTags: [
                'User',
            ],
        }),
        getInsuranceBroker: builder.query({
            query: getWithAppendedParameters('insurance-broker'),
            providesTags: [
                'InsuranceBroker',
                'Office',
                'User',
            ],
        }),
        getInsuranceBrokerCommissionSetting: builder.query({
            query: getWithAppendedParameters('insurance-broker-commission-setting'),
            providesTags: [
                'InsuranceBroker',
                'InsuranceCompany',
                'InsuranceBrokerCommissionSetting',
                'Product',
            ],
        }),
        getGreenCard: builder.query({
            query: getWithAppendedParameters('green-card'),
            providesTags: [
                'GreenCard',
                'Installment',
                'InsuranceBroker',
                'InsuranceCompany',
                'Office',
                'Policy',
                'Sticker',
                'User',
                'Voucher',
                'VoucherDetail',
            ],
        }),
        getGreenCardInventory: builder.query({
            query: getWithAppendedParameters('green-cards/inventory'),
            providesTags: [
                'GreenCard',
            ],
        }),
        getOffer: builder.query({
            query: getWithAppendedParameters('offer'),
            providesTags: [
                'Offer',
            ],
        }),
        getOffice: builder.query({
            query: getWithAppendedParameters('office'),
            providesTags: [
                'InsuranceBroker',
                'Office',
                'User',
            ],
        }),
        getPolicy: builder.query({
            query: getWithAppendedParameters('policy'),
            providesTags: [
                'Client',
                'GreenCard',
                'Installment',
                'InsuranceBroker',
                'InsuranceCompany',
                'Office',
                'Policy',
                'Product',
                'Sticker',
                'AssistanceCard',
                'User',
                'Vehicle',
                'Voucher',
                'VoucherDetail',
            ],
        }),
        getSticker: builder.query({
            query: getWithAppendedParameters('sticker'),
            providesTags: [
                'GreenCard',
                'Installment',
                'InsuranceBroker',
                'InsuranceCompany',
                'Office',
                'Policy',
                'Sticker',
                'User',
                'Voucher',
                'VoucherDetail',
            ],
        }),
        getStickerInventory: builder.query({
            query: getWithAppendedParameters('stickers/inventory'),
            providesTags: [
                'Sticker',
            ],
        }),
        getAssistanceCard: builder.query({
            query: getWithAppendedParameters('assistance-card'),
            providesTags: [
                'AssistanceCard',
                'InsuranceBroker',
                'InsuranceCompany',
                'Office',
                'Policy',
                'User',
            ],
        }),
        getAssistanceCardInventory: builder.query({
            query: getWithAppendedParameters('assistance-cards/inventory'),
            providesTags: [
                'AssistanceCard',
            ],
        }),
        getUabCard: builder.query({
            query: getWithAppendedParameters('uab-card'),
            providesTags: [
                'Client',
                'InsuranceBroker',
                'Office',
                'UabCard',
                'UabCardVoucher',
                'User',
                'Vehicle',
            ],
        }),
        getUabCardInventory: builder.query({
            query: getWithAppendedParameters('uab-cards/inventory'),
            providesTags: [
                'UabCard',
            ],
        }),
        getUser: builder.query({
            query: getWithAppendedParameters('user'),
            providesTags: [
                'InsuranceBroker',
                'Office',
                'User',
            ],
        }),
        getUserLevelCommissionSetting: builder.query({
            query: getWithAppendedParameters('user-level-commission-setting'),
            providesTags: [
                'UserLevel',
                'UserLevelCommissionSetting',
                'InsuranceCompany',
                'Product',
            ],
        }),
        getValuationCertificate: builder.query({
            query: getWithAppendedParameters('valuation-certificate'),
            providesTags: [
                'Client',
                'InsuranceBroker',
                'InsuranceCompany',
                'Office',
                'User',
                'ValuationCertificate',
                'Vehicle',
            ],
        }),
        getValuationCertificateInventory: builder.query({
            query: getWithAppendedParameters('valuation-certificates/inventory'),
            providesTags: [
                'ValuationCertificate',
            ],
        }),
        getVehicle: builder.query({
            query: getWithAppendedParameters('vehicle'),
            providesTags: [
                'Client',
                'InsuranceCompany',
                'Policy',
                'User',
                'Vehicle',
            ],
        }),

        listApplications: builder.query({
            query: postWithBody('applications'),
            providesTags: [
                'Application',
                'Product',
                'InsuranceBroker',
                'Office',
                'Product',
                'User',
            ],
        }),
        listBsoProtocols: builder.query({
            query: postWithBody('bso-protocols'),
            providesTags: [
                'BsoProtocol',
                'InsuranceBroker',
                'Office',
                'User',
                'Sticker',
                'GreenCard',
                'UabCard',
                'AssistanceCard',
            ],
        }),
        listClients: builder.query({
            query: postWithBody('clients'),
            providesTags: [
                'Client',
            ],
        }),
        listCurrentInsuranceBrokerCommissionSettings: builder.query({
            query: () => 'insurance-broker-commission-settings',
            providesTags: [
                'InsuranceBroker',
                'InsuranceBrokerCommissionSetting',
                'InsuranceCompany',
                'Product',
            ],
        }),
        listGreenCards: builder.query({
            query: postWithBody('green-cards'),
            providesTags: [
                //'Client',
                'GreenCard',
                'Installment',
                'InsuranceBroker',
                'InsuranceCompany',
                'Office',
                'Policy',
                'Sticker',
                'User',
                //'Vehicle',
                'Voucher',
                'VoucherDetail',
            ],
        }),
        listInsuranceCompanies: builder.query({
            query: () => 'insurance-companies',
            providesTags: [
                'InsuranceCompany',
            ],
        }),
        listPendingInstallments: builder.query({
            query: postWithBody('installments/pending'),
            providesTags: [
                'Client',
                'Installment',
                'InsuranceCompany',
                'Policy',
                'Vehicle',
                'Voucher',
                'Product',
            ],
        }),
        listPendingPolicies: builder.query({
            query: postWithBody('policies/pending'),
            providesTags: [
                'Client',
                'InsuranceCompany',
                'Policy',
                'Vehicle',
                'Voucher',
                'Product',
            ],
        }),
        listPendingAllPolicies: builder.query({
            query: postWithBody('policies/all-pending'),
            providesTags: [
                'Client',
                'InsuranceCompany',
                'Policy',
                'Vehicle',
                'Voucher',
                'Product',
            ],
        }),
        listPolicies: builder.query({
            query: postWithBody('policies'),
            providesTags: [
                'Client',
                'GreenCard',
                'Installment',
                'InsuranceBroker',
                'InsuranceCompany',
                'Office',
                'Policy',
                'Product',
                'Sticker',
                'User',
                'Vehicle',
                'Voucher',
                'VoucherDetail',
                'AssistanceCard',
            ],
        }),
        listStickers: builder.query({
            query: postWithBody('stickers'),
            providesTags: [
                //'Client',
                'GreenCard',
                'Installment',
                'InsuranceBroker',
                'InsuranceCompany',
                'Office',
                'Policy',
                'Sticker',
                'User',
                //'Vehicle',
                'Voucher',
                'VoucherDetail',
            ],
        }),
        listAssistanceCards: builder.query({
            query: postWithBody('assistance-cards'),
            providesTags: [
                'AssistanceCard',
                'InsuranceBroker',
                'InsuranceCompany',
                'Office',
                'Policy',
                'User',
            ],
        }),
        listUabCards: builder.query({
            query: postWithBody('uab-cards'),
            providesTags: [
                //'Client',
                'InsuranceBroker',
                'Office',
                'UabCard',
                'UabCardVoucher',
                'User',
                //'Vehicle',
            ],
        }),
        listVehicles: builder.query({
            query: postWithBody('vehicles'),
            providesTags: [
                'Vehicle',
            ],
        }),
        listVouchers: builder.query({
            query: postWithBody('vouchers'),
            providesTags: [
                'GreenCard',
                'Installment',
                'InsuranceBroker',
                'InsuranceCompany',
                'Office',
                'Policy',
                'Product',
                'Sticker',
                'User',
                'Voucher',
                'VoucherDetail',
            ],
        }),
        listVouchersForPayments: builder.query({
            query: postWithBody('vouchers-payments'),
            providesTags: [
                'GreenCard',
                'Installment',
                'InsuranceBroker',
                'InsuranceCompany',
                'Office',
                'Policy',
                'Product',
                'Sticker',
                'User',
                'Voucher',
                'VoucherDetail',
            ],
        }),
        listUabCardsVoucher: builder.query({
            query: postWithBody('uab-cards-voucher'),
            providesTags: [
                'InsuranceBroker',
                'Office',
                'User',
                'UabCard',
                'UabCardVoucher',
                'Client',
                'Vehicle'
            ],
        }),
        listUserLevels: builder.query({
            query: () => 'user-levels',
            providesTags: [
                'UserLevel'
            ],
        }),
        listCurrentUserLevelCommissionSettings: builder.query({
            query: () => 'user-level-commission-settings',
            providesTags: [
                'UserLevel',
                'UserLevelCommissionSetting',
                'InsuranceCompany',
                'Product',
            ],
        }),
        listProducts: builder.query({
            query: () => 'nomenclatures/products',
            providesTags: [
                'Product',
            ],
        }),
        listFscCategories: builder.query({
            query: () => '/nomenclatures/fsc-categories',
            providesTags: [
                'FscCategory',
            ],
        }),
        listCountries: builder.query({
            query: () => '/nomenclatures/countries',
            providesTags: [
                'Country',
            ],
        }),
        listMunicipalities: builder.query({
            query: getWithAppendedParameters('/nomenclatures/municipalities'),
            providesTags: [
                'State',
            ],
        }),
        listRegions: builder.query({
            query: getWithAppendedParameters('/nomenclatures/regions'),
            providesTags: [
                'State',
            ],
        }),
        listCities: builder.query({
            query: getWithAppendedParameters('/nomenclatures/cities'),
            providesTags: [
                'State',
            ],
        }),

        createBsoProtocol: builder.mutation({
            query: postWithBody('bso-protocol'),
            invalidatesTags: [
                'BsoProtocol',
            ],
        }),
        createBsoProtocolStickers: builder.mutation({
            query: ([id, params]) => ({
                url: 'bso-protocol/' + id + '/stickers',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: [
                'BsoProtocol',
            ],
        }),
        createBsoProtocolAssistanceCards: builder.mutation({
            query: ([id, params]) => ({
                url: 'bso-protocol/' + id + '/assistance-cards',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: [
                'BsoProtocol',
            ],
        }),
        createBsoProtocolGreenCards: builder.mutation({
            query: ([id, params]) => ({
                url: 'bso-protocol/' + id + '/green-cards',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: [
                'BsoProtocol',
            ],
        }),
        createBsoProtocolUabCards: builder.mutation({
            query: ([id, params]) => ({
                url: 'bso-protocol/' + id + '/uab-cards',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: [
                'BsoProtocol',
            ],
        }),
        createGreenCards: builder.mutation({
            query: postWithBody('green-card'),
            invalidatesTags: [
                'GreenCard',
            ],
        }),
        createMtplApplication: builder.mutation({
            query: postWithBody('application/mtpl'),
            invalidatesTags: [
                'Application',
            ],
        }),
        createOffers: builder.query({
            query: postWithBody('offer'),
            invalidatesTags: [
                'Offer',
            ],
        }),
        createClient: builder.mutation({
            query: postWithBody('client'),
            invalidatesTags: [
                'Client',
            ],
        }),
        createVehicle: builder.mutation({
            query: postWithBody('vehicle'),
            invalidatesTags: [
                'Vehicle',
            ],
        }),
        createPolicy: builder.mutation({
            query: postWithBody('policy'),
            invalidatesTags: [
                'Installment',
                'Policy',
            ],
        }),
        createPolicyFromInsuranceCompanyByPolicyNumber: builder.mutation({
            query: postWithBody('policy/from-insurance-company/by-policy-number'),
            invalidatesTags: [
                'GreenCard',
                'Installment',
                'Policy',
                'Sticker',
                'Voucher',
                'VoucherDetail',
            ],
        }),
        createPolicyFromOffer: builder.mutation({
            query: postWithBody('policy/from-offer'),
            invalidatesTags: [
                'GreenCard',
                'Installment',
                'Policy',
                'Sticker',
                'Voucher',
                'VoucherDetail',
            ],
        }),
        createStickers: builder.mutation({
            query: postWithBody('sticker'),
            invalidatesTags: [
                'Sticker',
            ],
        }),
        createUabCards: builder.mutation({
            query: postWithBody('uab-card'),
            invalidatesTags: [
                'UabCard',
            ],
        }),
        createValuationCertificates: builder.mutation({
            query: postWithBody('valuation-certificate'),
            invalidatesTags: [
                'ValuationCertificate',
            ],
        }),
        createAssistanceCards: builder.mutation({
            query: postWithBody('assistance-card'),
            invalidatesTags: [
                'AssistanceCard',
            ],
        }),
        createVoucherForPayment: builder.mutation({
            query: postWithBody('voucher/for-payment'),
            invalidatesTags: [
                'GreenCard',
                'Installment',
                'Sticker',
                'Voucher',
                'VoucherDetail',
            ],
        }),

        voidVoucherInInsuranceCompany: builder.mutation({
            query: postWithBody('voucher/void-in-ic'),
            invalidatesTags: [
                'Installment',
                'Voucher',
                'VoucherDetail',
                'UserVoucherDetail',
            ],
        }),

        reportSalesSummary: builder.query({
            query: postWithBody('report/sales/summary'),
            providesTags: [
                'Voucher',
            ],
        }),

        reportSalesRanking: builder.query({
            query: postWithBody('report/sales/ranking'),
            providesTags: [
                'User',
                'Office',
            ],
        }),
        reportFsc: builder.query({
            query: postWithBody('/report/fsc'),
            providesTags: [
                'FscCategory',
            ],
        }),

        sendVoucherEmail: builder.mutation({
            query: voucherId => ({
                url: 'voucher/' + voucherId + '/email',
                method: 'POST',
            }),
        }),

        storeVoucher: builder.mutation({
            query: voucherId => ({
                url: 'voucher/' + voucherId + '/stored',
                method: 'POST',
            }),
            invalidatesTags: [
                'Voucher',
            ],
        }),

        sellUabCard: builder.mutation({
            query: params => ({
                url: 'uab-card/' + params.uabCard + '/sold',
                method: 'POST',
                body: {
                    pin: params.pin,
                },
            }),
            invalidatesTags: [
                'UabCard',
                'UabCardVoucher',
            ],
        }),

        completeBsoProtocol: builder.mutation({
            query: id => ({
                url: 'bso-protocol/' + id + '/completed',
                method: 'PATCH',
            }),
            invalidatesTags: [
                'BsoProtocol',
            ],
        }),
        sendBsoProtocol: builder.mutation({
            query: id => ({
                url: 'bso-protocol/' + id + '/sent',
                method: 'PATCH',
            }),
            invalidatesTags: [
                'BsoProtocol',
            ],
        }),
        receiveBsoProtocol: builder.mutation({
            query: id => ({
                url: 'bso-protocol/' + id + '/received',
                method: 'PATCH',
            }),
            invalidatesTags: [
                'BsoProtocol',
            ],
        }),
        returnBsoProtocol: builder.mutation({
            query: id => ({
                url: 'bso-protocol/' + id + '/return',
                method: 'PATCH',
            }),
            invalidatesTags: [
                'BsoProtocol',
            ],
        }),

        updateClient: builder.mutation({
            query: ([id, params]) => ({
                url: 'client/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'Client',
            ],
        }),
        updateGreenCard: builder.mutation({
            query: ([id, params]) => ({
                url: 'green-card/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'GreenCard',
            ],
        }),
        updateInsuranceBroker: builder.mutation({
            query: ([id, params]) => ({
                url: 'insurance-broker/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'InsuranceBroker',
            ],
        }),
        updateInsuranceBrokerCommissionSetting: builder.mutation({
            query: ([id, params]) => ({
                url: 'insurance-broker-commission-setting/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'InsuranceBrokerCommissionSetting',
                'VoucherDetail',
            ],
        }),
        updateInsuranceCompany: builder.mutation({
            query: ([id, params]) => ({
                url: 'insurance-company/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'InsuranceCompany',
            ],
        }),
        updateInsuranceCompanyMtplAdditionalAmount: builder.mutation({
            query: ([id, params]) => ({
                url: 'mtpl-additional-amount/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'MtplAdditionalAmount',
            ],
        }),
        updateOffice: builder.mutation({
            query: ([id, params]) => ({
                url: 'office/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'Office',
            ],
        }),
        updatePolicy: builder.mutation({
            query: ([id, params]) => ({
                url: 'policy/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'Policy',
            ],
        }),
        attachPolicyParticipants: builder.mutation({
            query: ([id, params]) => ({
                url: 'policy/attach-participants/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'Policy',
            ],
        }),
        attachPolicyAssistanceCard: builder.mutation({
            query: ([id, assistanceCardId]) => ({
                url: 'policy/attach-assistance-card/' + id + '/' + assistanceCardId,
                method: 'PATCH',
            }),
            invalidatesTags: [
                'Policy',
            ],
        }),
        updateSticker: builder.mutation({
            query: ([id, params]) => ({
                url: 'sticker/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'Sticker',
            ],
        }),
        updateAssistanceCard: builder.mutation({
            query: ([id, params]) => ({
                url: 'assistance-card/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'AssistanceCard',
            ],
        }),
        updateUabCard: builder.mutation({
            query: ([id, params]) => ({
                url: 'uab-card/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'UabCard',
            ],
        }),
        updateUser: builder.mutation({
            query: ([id, params]) => ({
                url: 'user/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'User',
            ],
        }),
        updateUserRelations: builder.mutation({
            query: ([id, params]) => ({
                url: 'user/' + id + '/relations',
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'Voucher',
            ],
        }),
        updateUserLevelCommissionSetting: builder.mutation({
            query: ([id, params]) => ({
                url: 'user-level-commission-setting/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'UserLevelCommissionSetting',
                'UserVoucherDetail',
            ],
        }),
        updateValuationCertificate: builder.mutation({
            query: ([id, params]) => ({
                url: 'valuation-certificate/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'ValuationCertificate',
            ],
        }),
        updateVehicle: builder.mutation({
            query: ([id, params]) => ({
                url: 'vehicle/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'Vehicle',
            ],
        }),
        updateVehicleFromVinDecoder: builder.mutation({
            query: id => ({
                url: 'vehicle/vin-decoder/' + id,
                method: 'PATCH',
            }),
            invalidatesTags: [
                'Vehicle',
            ],
        }),
        updateVoucher: builder.mutation({
            query: ([id, params]) => ({
                url: 'voucher/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'Voucher',
            ],
        }),
        voidVoucher: builder.mutation({
            query: id => ({
                url: 'voucher/void/' + id,
                method: 'PATCH',
            }),
            invalidatesTags: [
                'Voucher',
            ],
        }),
        setOffForDamageVoucher: builder.mutation({
            query: id => ({
                url: 'voucher/damage/' + id,
                method: 'PATCH',
            }),
            invalidatesTags: [
                'Voucher',
            ],
        }),
        updateVoucherDetails: builder.mutation({
            query: id => ({
                url: 'voucher/details/' + id,
                method: 'PATCH',
            }),
            invalidatesTags: [
                'Voucher',
            ],
        }),
        updateVouchersPayments: builder.mutation({
            query: postWithBody('vouchers/update-payments'),
            invalidatesTags: [
                'Voucher',
            ],
        }),
        updateUserLimit: builder.mutation({
            query: ([id, params]) => ({
                url: 'user-limit-update/' + id,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'UserLimit',
            ],
        }),
        attachVoucherBso: builder.mutation({
            query: ([id, params]) => ({
                url: 'voucher/' + id + '/bso',
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: [
                'Voucher',
            ],
        }),

        attachVoucherPublishers: builder.mutation({
            query: ([id, issueUserId]) => ({
                url: 'voucher/publishers/' + id + '/' + issueUserId,
                method: 'PATCH',
            }),
            invalidatesTags: [
                'Voucher',
            ],
        }),

        attachVoucherCommissionUser: builder.mutation({
            query: ([id, commissionUserId]) => ({
                url: 'voucher/commission/' + id + '/' + commissionUserId,
                method: 'PATCH',
            }),
            invalidatesTags: [
                'Voucher',
            ],
        }),

        issueAnnex: builder.mutation({
            query: postWithBody('annex/issue'),
            invalidatesTags: [
                'Voucher',
            ],
        }),

        fillClientAddress: builder.mutation({
            query: postWithBody('client/fill-address'),
            invalidatesTags: [
                'Client',
            ],
        }),

        uploadPolicyFile: builder.mutation({
            query: ([id, params]) => ({
                url: '/print/upload/policy/' + id,
                method: 'POST',
                body: params,
            }),
        }),
        uploadPolicyAdditionalFile: builder.mutation({
            query: ([id, params]) => ({
                url: '/print/upload/policy/additional/' + id,
                method: 'POST',
                body: params,
            }),
        }),
        uploadVoucherFile: builder.mutation({
            query: ([id, params]) => ({
                url: '/print/upload/voucher/' + id,
                method: 'POST',
                body: params,
            }),
        }),
        uploadGreenCardFile: builder.mutation({
            query: ([id, params]) => ({
                url: '/print/upload/green-card/' + id,
                method: 'POST',
                body: params,
            }),
        }),
        uploadVehicleFile: builder.mutation({
            query: ([id, params]) => ({
                url: '/print/upload/vehicle/' + id,
                method: 'POST',
                body: params,
            }),
        }),

        addUserLimit: builder.mutation ({
            query: ([id, params]) => ({
                url: 'user-limit-add/' + id,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: [
                'User',
                'UserLimit',
                'UserLimitAudit'
            ],
        }),

        deleteBsoProtocolStickers: builder.mutation({
            query: ([id, params]) => ({
                url: 'bso-protocol/' + id + '/stickers',
                method: 'DELETE',
                body: params,
            }),
            invalidatesTags: [
                'BsoProtocol',
            ],
        }),
        deleteBsoProtocolAssistanceCards: builder.mutation({
            query: ([id, params]) => ({
                url: 'bso-protocol/' + id + '/assistance-cards',
                method: 'DELETE',
                body: params,
            }),
            invalidatesTags: [
                'BsoProtocol',
            ],
        }),
        deleteBsoProtocolGreenCards: builder.mutation({
            query: ([id, params]) => ({
                url: 'bso-protocol/' + id + '/green-cards',
                method: 'DELETE',
                body: params,
            }),
            invalidatesTags: [
                'BsoProtocol',
            ],
        }),
        deleteBsoProtocolUabCards: builder.mutation({
            query: ([id, params]) => ({
                url: 'bso-protocol/' + id + '/uab-cards',
                method: 'DELETE',
                body: params,
            }),
            invalidatesTags: [
                'BsoProtocol',
            ],
        }),

        listPrecalculateUserCommissionAmount: builder.mutation({
            query: postWithBody('voucher/precalculate-user-commission'),
        }),
    }),
});

export const {
    useGetCalculatorConfigQuery,
    useGetVersionQuery,
    useLoginMutation,
} = anonymousApiSlice;

export const {
    useGetApplicationQuery,
    useGetBsoProtocolQuery,
    useGetClientQuery,
    useGetCurrentInsuranceBrokerQuery,
    useGetCurrentOfficeQuery,
    useGetCurrentUserQuery,
    useCurrentUserHasRoleQuery,
    useGetInsuranceBrokerQuery,
    useGetInsuranceBrokerCommissionSettingQuery,
    useGetGreenCardInventoryQuery,
    useGetGreenCardQuery,
    useGetOfferQuery,
    useGetOfficeQuery,
    useGetPolicyQuery,
    useGetStickerQuery,
    useGetStickerInventoryQuery,
    useGetAssistanceCardQuery,
    useGetAssistanceCardInventoryQuery,
    useGetUabCardQuery,
    useGetUabCardInventoryQuery,
    useGetUserQuery,
    useGetUserLevelCommissionSettingQuery,
    useGetValuationCertificateQuery,
    useGetValuationCertificateInventoryQuery,
    useGetVehicleQuery,

    useListApplicationsQuery,
    useListBsoProtocolsQuery,
    useListClientsQuery,
    useLazyListClientsQuery,
    useListCurrentInsuranceBrokerCommissionSettingsQuery,
    useListGreenCardsQuery,
    useListUabCardsQuery,
    useListInsuranceCompaniesQuery,
    useListPendingInstallmentsQuery,
    useLazyListPendingInstallmentsQuery,
    useListPendingPoliciesQuery,
    useLazyListPendingPoliciesQuery,
    useListPendingAllPoliciesQuery,
    useLazyListPendingAllPoliciesQuery,
    useListPoliciesQuery,
    useLazyListPoliciesQuery,
    useListStickersQuery,
    useListAssistanceCardsQuery,
    useListVehiclesQuery,
    useLazyListVehiclesQuery,
    useListVouchersQuery,
    useLazyListVouchersQuery,
    useListVouchersForPaymentsQuery,
    useLazyListVouchersForPaymentsQuery,
    useListUabCardsVoucherQuery,
    useLazyListUabCardsVoucherQuery,
    useListUserLevelsQuery,
    useListCurrentUserLevelCommissionSettingsQuery,
    useListProductsQuery,
    useListFscCategoriesQuery,
    useListCountriesQuery,
    useListMunicipalitiesQuery,
    useListRegionsQuery,
    useListCitiesQuery,

    useCreateGreenCardsMutation,
    useCreateBsoProtocolMutation,
    useCreateBsoProtocolStickersMutation,
    useCreateBsoProtocolAssistanceCardsMutation,
    useCreateBsoProtocolGreenCardsMutation,
    useCreateBsoProtocolUabCardsMutation,
    useCreateMtplApplicationMutation,
    useCreateOffersQuery,
    useCreateClientMutation,
    useCreateVehicleMutation,
    useCreatePolicyMutation,
    useCreatePolicyFromInsuranceCompanyByPolicyNumberMutation,
    useCreatePolicyFromOfferMutation,
    useCreateStickersMutation,
    useCreateUabCardsMutation,
    useCreateValuationCertificatesMutation,
    useCreateAssistanceCardsMutation,
    useCreateVoucherForPaymentMutation,
    useVoidVoucherInInsuranceCompanyMutation,

    useReportSalesSummaryQuery,
    useReportSalesRankingQuery,
    useReportFscQuery,
    useLazyReportFscQuery,

    useSendVoucherEmailMutation,

    useStoreVoucherMutation,

    useSellUabCardMutation,

    useCompleteBsoProtocolMutation,
    useSendBsoProtocolMutation,
    useReceiveBsoProtocolMutation,
    useReturnBsoProtocolMutation,

    useUpdateClientMutation,
    useUpdateGreenCardMutation,
    useUpdateInsuranceBrokerMutation,
    useUpdateInsuranceBrokerCommissionSettingMutation,
    useUpdateInsuranceCompanyMutation,
    useUpdateInsuranceCompanyMtplAdditionalAmountMutation,
    useUpdateOfficeMutation,
    useUpdatePolicyMutation,
    useAttachPolicyParticipantsMutation,
    useAttachPolicyAssistanceCardMutation,
    useUpdateStickerMutation,
    useUpdateAssistanceCardMutation,
    useUpdateUabCardMutation,
    useUpdateUserMutation,
    useUpdateUserRelationsMutation,
    useUpdateUserLevelCommissionSettingMutation,
    useUpdateValuationCertificateMutation,
    useUpdateVehicleMutation,
    useUpdateVehicleFromVinDecoderMutation,
    useUpdateVoucherMutation,
    useVoidVoucherMutation,
    useSetOffForDamageVoucherMutation,
    useUpdateVoucherDetailsMutation,
    useUpdateVouchersPaymentsMutation,
    useUpdateUserLimitMutation,
    useAttachVoucherBsoMutation,
    useAttachVoucherPublishersMutation,
    useAttachVoucherCommissionUserMutation,
    useIssueAnnexMutation,
    useFillClientAddressMutation,
    useUploadPolicyFileMutation,
    useUploadPolicyAdditionalFileMutation,
    useUploadVoucherFileMutation,
    useUploadGreenCardFileMutation,
    useUploadVehicleFileMutation,

    useAddUserLimitMutation,
    useDeleteBsoProtocolStickersMutation,
    useDeleteBsoProtocolAssistanceCardsMutation,
    useDeleteBsoProtocolGreenCardsMutation,
    useDeleteBsoProtocolUabCardsMutation,

    useListPrecalculateUserCommissionAmountMutation,

} = authenticatedApiSlice;

const offerFilter = (state, filterQuery) =>
    [].concat(...Object.entries(state[authenticatedApiSlice.reducerPath].queries)
        .filter(([label, query]) => label.indexOf('createOffers(') === 0 && filterQuery(query))
        .map(([, {data}]) => data)
    );

export const offerSelector = (applicationId, insuranceCompanyId, installmentsCount) => state =>
    offerFilter(state, query =>
        query.originalArgs.application === applicationId &&
        query.originalArgs.insuranceCompany === insuranceCompanyId &&
        query.status === 'fulfilled'
    )
        .filter(offer => offer.installmentsCount === installmentsCount)
        .shift();

export const bestOfferSelector = (applicationId, installmentsCount) => state =>
    offerFilter(state, query =>
        query.originalArgs.application === applicationId &&
        query.status === 'fulfilled'
    )
        .filter(offer => offer.installmentsCount === installmentsCount)
        .sort((a, b) =>
            sum(a.installments.map(inst => inst.totalAmount)) -
            sum(b.installments.map(inst => inst.totalAmount))
        )
        .shift();

export const offersProgressSelector = (applicationId) => state => {
    const allOffers = Object.entries(state[authenticatedApiSlice.reducerPath].queries)
        .filter(([label, query]) =>
            label.indexOf('createOffers(') === 0 &&
            query.originalArgs.application === applicationId
        );

    if (allOffers.length === 0) {
        return 0;
    }

    const completedOffers = allOffers.filter(([, query]) =>
        ['fulfilled', 'rejected'].indexOf(query.status) > -1
    );

    return completedOffers.length / allOffers.length;
};
